























































































































































































































import Page from '@/Page.vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { CrudAction, CrudReponse } from '@/libs/core/+state/models/crud-action';
import {
	CategoryModel,
	FindingFileModel,
	FindingModel,
	ImplementationStateEnum,
	MeasureModel,
	PlanItemModel,
	ResponseBasicModel,
	ResponseModel,
	TenantUserModel
} from '@/libs/Api';
import WorkTaskFindingItemMeasure from './WorkTaskFindingItemMeasure.vue';
import { cloneDeep } from 'lodash';
import { measuresStore } from '@/libs/measures/+state/store';
import moment from 'moment';
import { planItemsStore } from '@/libs/plan-items/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { authStore } from '@/libs/auth/+store/store';
import { AuthGetter } from '@/libs/auth/models/auth-state';
import WorkTaskDialogFiles from '../components/WorkTaskDialogFiles.vue';

@Component({
    components: {
        WorkTaskFindingItemMeasure,
        WorkTaskDialogFiles
    }
})
export default class WorkTaskVerifyMeasureItem extends Page {

    /* Component props */
    @PropSync("measure") item!: MeasureModel;
    @Prop() category!: CategoryModel;
    @Prop() finding!: FindingModel;

    /* Auth variables */
    tenantUser: TenantUserModel = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
    
    /* Component state variables */
    dialog = false;
    dialogReturn = false;
    valid = true;
    saving = false;
    hasChanges = true;
    dialogModel: MeasureModel | null = null;

    get storedPlanItem (): PlanItemModel {
        return planItemsStore.useGetter(CrudGetter.Detail, this.item.finding?.response?.planItemId) as PlanItemModel;
    }

    get canEditVerification(): boolean {
        return this.item.measureVerificationUsers!.any(n => n.tenantUserId == this.tenantUser.id);
    }

    get response(): ResponseBasicModel {
        return this.finding.response!;
    }

    get findingFiles(): FindingFileModel[] {
        return this.finding?.findingFiles ?? [];
    }

	get storedResponse(): ResponseModel | null {
		const planItem = planItemsStore.useGetter(CrudGetter.Detail, this.item.finding?.response?.planItemId) as PlanItemModel;
		return planItem?.responses?.firstOrDefault(x => x.id == this.finding.responseId) ?? null;
	}

    /* Action functions */
    
    openDialog () {
        this.dialogModel = cloneDeep(this.item);
        this.dialog = true;
    }

    closeDialog () {
        this.dialog = false;
    }
    
    markVerified () {
        this.saving = true;
        const dialogModel = cloneDeep(this.dialogModel);
        dialogModel!.implementationState = ImplementationStateEnum.Verified;
        measuresStore.dispatch(CrudAction.Update, { item: dialogModel });
        this.subscribe(measuresStore, CrudReponse.Update).then((e) => {
            this.saving = false;
            this.$emit("planItemChanged");
            this.closeDialog();
        }).catch((e) => {
            this.saving = false;
            console.log(e);
        });
    }

    returnToImplementation () {
        this.saving = true;
        this.dialogReturn = false;
        const dialogModel = cloneDeep(this.dialogModel);
        dialogModel!.implementationState = ImplementationStateEnum.New;
        measuresStore.dispatch(CrudAction.Update, { item: dialogModel });
        this.subscribe(measuresStore, CrudReponse.Update).then((e) => {
            this.saving = false;
            this.$emit("planItemChanged");
            this.closeDialog();
        }).catch((e) => {
            this.saving = false;
            console.log(e);
        });
    }

}
