export enum BusinessModule {
    NULL = '',
    Category = 'Category',
    CategoryUser = 'CategoryUser',
    Workplace = 'Workplace',
    Audit = 'Audit',
    QuestionGroup = 'QuestionGroup',
    Question = 'Question',
    Measure = 'Measure',
    Verification = 'Verification',
    Finding = 'Finding',
    AuditTiming = 'AuditTiming',
    PlanItem = 'PlanItem',
    Response = 'Response',
    Issue = 'Issue',
    IssueResponse = 'IssueResponse',
    Unanswered = 'Unanswered',
    Group = 'Group',
    GroupUser = 'GroupUser',
    Codebook = 'Codebook',
    NotRatedOptionReason = 'NotRatedOptionReason',
    Tenant = 'Tenant',
    TenantUser = 'TenantUser',
    TenantConnection = 'TenantConnection',
    TenantInvitation = 'TenantInvitation',
    ApplicationUser = 'ApplicationUser',
    File = 'File',
    Role = 'Role',
    UserRole = 'UserRole',
    Permission = 'Permission',
    SolvedIssue = 'SolvedIssue',
    AdminTenant = 'AdminTenant',
    AdminUser = 'AdminUser',
    SystemUser = 'SystemUser',
    AdminTenantUser = 'AdminTenantUser',
    Department = 'Department',
    Position = 'Position',
    Import = 'Import',
    AiServiceQueueItem = 'AiServiceQueueItem',
}
