






















































































































































































































import {
    AuditExplanationTypeEnum,
    AuditModel,
    PermissionTypeEnum,
    ProcessStepModel,
    ProductGroupModel,
    ResponseTypeEnum,
    TenantUserModel
} from '@/libs/Api';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import {Component, Prop, Ref} from 'vue-property-decorator';
import {auditsStore} from '../+state/store';
import AuditCreateDialog from '../components/AuditCreateDialog.vue';
import {cloneDeep, isEqual} from "lodash";
import {Guid} from "@/libs/common/functions/guid";
import {VDA63} from "@/audit-settings/VDA63";

@Component({
    components: {
        AuditCreateDialog
    }
})
export default class General extends Page {

    @Prop() tenantId!: string;
    @Prop() auditId!: string;
    districtId: string | null = null;
    auditOriginal: AuditModel | null = null;
    audit: AuditModel | null = null;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    deleteDialog = false;
    deleting = false;
    valid = false;
    loading = true;
    hiddenProcessSteps: ProcessStepModel[] = [];
    originalShownProcessStepsCount: number | null = 0;
    shownProcessStepsCount: number | null = 0;
    processStepsCount: string | null = "";

    @Ref() form!: any;
    @Ref() auditCreateDialog!: AuditCreateDialog;

    get isReadonly (): boolean {
        const tenantUser = authStore.useGetter(AuthGetter.GetTenantUser) as TenantUserModel;
        return !this.hasPermissions([PermissionTypeEnum.EditAllAudits]) && !(this.hasPermissions([PermissionTypeEnum.EditOwnedAudit]) && this.audit?.ownerId == tenantUser?.id);
    }

    get saving(): boolean {
        return auditsStore.useGetter(CrudGetter.Saving);
    }

    get hasChanges () {
        return !isEqual(this.auditOriginal, this.audit) || this.originalShownProcessStepsCount != this.shownProcessStepsCount;
    }

    get enableExplanationByCategory() {
        return this.audit?.category?.useExplanationByCategory == true;
    }
    
    get isVda63(): boolean {
        return this.audit?.category?.responseType == ResponseTypeEnum.VDA63;
    }

    mounted () {
        this.audit = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;
        this.hiddenProcessSteps = this.audit!.processSteps!.where(x => x.isHidden == true).toArray();
        this.audit!.processSteps = this.audit!.processSteps!.where(x => x.isHidden != true).toArray();
        this.auditOriginal = {...cloneDeep(auditsStore.useGetter(CrudGetter.Detail))} as AuditModel;
        this.originalShownProcessStepsCount = this.audit!.processSteps!.count();
        this.loading = false;
        if (this.audit.explanationType == AuditExplanationTypeEnum.ByCategory && this.audit.category?.useExplanationByCategory != true) {
            this.audit.explanationType = AuditExplanationTypeEnum.None;
            this.save(false);
        }
        if (this.audit.category?.responseType == ResponseTypeEnum.VDA63) {
            this.processStepsCount = this.audit.processSteps!.any() ? this.audit.processSteps!.count().toString() : null;
            this.shownProcessStepsCount = this.processStepsCount != null ? Number(this.processStepsCount) : 0;
        }
    }

    save (validate = true) {
        if (!validate || this.form.validate()) {
            this.audit!.processSteps = this.audit!.processSteps!.take(this.shownProcessStepsCount ?? 0).toArray();
            this.audit!.processSteps!.forEach(x => x.position = this.audit!.processSteps!.indexOf(x) + 1);
            this.audit!.productGroups!.forEach(x => x.position = this.audit!.productGroups!.indexOf(x) + 1);
            const audit = {...this.audit};
            if (!audit!.useProductGroups) {
                audit!.productGroups = [];
            }
            this.hiddenProcessSteps.forEach(x => audit.processSteps!.push(x));
            if (!audit.processSteps!.any(x => x.stepNumber == 'Product')) {
                audit.processSteps!.push({
                    id: Guid.EmptyGuid(),
                    auditId: this.auditId,
                    name: 'Produkt',
                    stepNumber: 'Product',
                    key: Guid.NewGuid(),
                    isHidden: true,
                    position: 1
                });
            }
            if (!audit.processSteps!.any(x => x.stepNumber == 'Process')) {
                audit.processSteps!.push({
                    id: Guid.EmptyGuid(),
                    auditId: this.auditId,
                    name: 'Proces',
                    stepNumber: 'Process',
                    key: Guid.NewGuid(),
                    isHidden: true,
                    position: 2
                });
            }
            const productGroupProcessSteps = audit.productGroupProcessSteps;
            audit.productGroupProcessSteps = this.auditOriginal!.productGroupProcessSteps;
            auditsStore.dispatch(CrudAction.Update, { item: audit });
            this.subscribe(auditsStore, CrudReponse.Update).then((updatedAudit: AuditModel) => {
                if (updatedAudit.useProductGroups) {
                    productGroupProcessSteps!.forEach(x => {
                        x.processStepId =  updatedAudit.processSteps!.first(n => n.key == x.processStep!.key).id;
                        x.productGroupId =  updatedAudit.productGroups!.first(n => n.key == x.productGroup!.key).id;
                    });
                    updatedAudit.productGroupProcessSteps = productGroupProcessSteps;
                }
                else {
                    updatedAudit.productGroupProcessSteps = [];
                }
                auditsStore.dispatch(CrudAction.Update, { item: updatedAudit });
                this.subscribe(auditsStore, CrudReponse.Update).then((e) => {
                    this.audit = e;
                    this.hiddenProcessSteps = this.audit!.processSteps!.where(x => x.isHidden == true).toArray();
                    this.audit!.processSteps = this.audit!.processSteps!.where(x => x.isHidden != true).toArray();
                    this.auditOriginal = {...cloneDeep(auditsStore.useGetter(CrudGetter.Detail))} as AuditModel;
                    this.originalShownProcessStepsCount = this.audit!.processSteps!.count();
                });
            });
        }
    }

    deleteAuditClick () {
        this.deleteDialog = true;
    }

    deleteAudit () {
        this.deleting = true;
        auditsStore.dispatch(CrudAction.Delete, { id: this.auditId });
        this.subscribe(auditsStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.$router.push({ name: "Audits", params: { tenantId: this.tenantId } });
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

    cloneAudit () {
        this.auditCreateDialog.clone();
    }
    
    onProcessStepsCountChange() {
        const processStepsCount = Math.max(!isNaN(Number(this.processStepsCount)) ? Number(this.processStepsCount) : 0, 0);
        if (processStepsCount > this.audit!.processSteps!.count()) {
            for (var i = this.audit!.processSteps!.count(); i < processStepsCount; i++) {
                this.audit!.processSteps!.push({
                    id: Guid.EmptyGuid(),
                    auditId: this.auditId,
                    name: null,
                    key: Guid.NewGuid()
                })
            }
        }
        else if (processStepsCount < this.audit!.processSteps!.count()) {
            for (var n = this.audit!.processSteps!.count(); n > processStepsCount; n--) {
                if ((this.audit!.processSteps![n - 1].name != null && this.audit!.processSteps![n - 1].name != '') || (this.audit!.processSteps![n - 1].stepNumber != null && this.audit!.processSteps![n - 1].stepNumber != '')) {
                    break;
                }
                this.audit!.processSteps!.splice(n - 1, 1);
            }
        }
        this.shownProcessStepsCount = !isNaN(Number(this.processStepsCount)) ? Number(this.processStepsCount) : 0;
    }
    
    onAddProductGroupClick() {
        this.audit!.productGroups!.push({
            position: 0,
            name: null,
            key: Guid.NewGuid()
        });
    }
    
    hasProductGroupProcessStep(productGroup: ProductGroupModel, processStep: ProcessStepModel) {
        console.log(this.audit!.productGroupProcessSteps!.any(x => x.productGroup!.key == productGroup.key && x.processStep!.key == processStep.key));
        return this.audit!.productGroupProcessSteps!.any(x => x.productGroup!.key == productGroup.key && x.processStep!.key == processStep.key);
    }
    
    setProductGroupProcessStepChecked($event: any, productGroup: ProductGroupModel, processStep: ProcessStepModel) {
        if (this.hasProductGroupProcessStep(productGroup, processStep) && $event == false) {
            const existingItem = this.audit!.productGroupProcessSteps!.first(x => x.productGroup!.key == productGroup.key && x.processStep!.key == processStep.key);
            const existingItemIndex = this.audit!.productGroupProcessSteps!.indexOf(existingItem);
            this.audit!.productGroupProcessSteps!.splice(existingItemIndex, 1);
        }
        else if (!this.hasProductGroupProcessStep(productGroup, processStep) && $event == true) {
            this.audit!.productGroupProcessSteps!.push({
                id: Guid.EmptyGuid(),
                auditId: this.auditId,
                processStep: processStep,
                productGroup: productGroup
            });
        }
    }
    
}
