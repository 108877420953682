











































































































































































































































































import {
  AiSuggestedQuestion,
  AuditExplanationTypeEnum,
  AuditModel,
  EscalationTypeEnum,
  FileModel,
  PermissionTypeEnum,
  QuestionFileModel,
  QuestionGroupModel,
  QuestionModel,
  ResponseTypeEnum,
  TenantModel
} from '@/libs/Api';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import Page from '@/Page.vue';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import {auditsStore} from '../+state/store';
import {questionGroupsStore} from '@/libs/question-groups/+state/store';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {Guid} from '@/libs/common/functions/guid';
import {cloneDeep, isEqual} from 'lodash';
import {authStore} from '@/libs/auth/+store/store';
import {AuthGetter} from '@/libs/auth/models/auth-state';
import {tenantsStore} from '@/libs/tenants/+state/store';
import axios from 'axios';
import {getAccessToken} from 'axios-jwt';
import moment from 'moment';
import draggable from 'vuedraggable'
import QuestionFileItem from '../components/QuestionFileItem.vue';
import QuestionFileDetail from '../components/QuestionFileDetail.vue';
import {VDA63, Vda63QuestionModel} from '@/audit-settings/VDA63';
import AiQuestionGenerator, {GenerationActionEnum} from "@/libs/audits/components/AiQuestionGenerator.vue";
import AiQuestionImprove from "@/libs/audits/components/AiQuestionImprove.vue";

@Component({
    components: {
      AiQuestionImprove,
      AiQuestionGenerator,
        QuestionFileItem, QuestionFileDetail, draggable
    }
})
export default class Questions extends Page {

    @Prop() tenantId!: string;
    @Prop() auditId!: string;
    districtId: string | null = null;
    audit: AuditModel | null = null;
    auditOriginal: AuditModel | null = null;
    basicRules = [
        (v: any) => !!v || this.$t('validation.thisFieldIsRequired')
    ];
    deleteDialog = false;
    deleting = false;
    valid = false;
    loading = true;
    saving = false;
    questionGroupsToDelete: QuestionGroupModel[] = [];
    questionGroups: QuestionGroupModel[] = [];
    questionGroupsOriginal: QuestionGroupModel[] = [];
    hasChanges = false;
    uniformExplanations = true;
    showResponsiveValue: number | null = null;
    responseValues = [
        { value: 1, color: "1fbf1f", escalationType: EscalationTypeEnum.NoEscalation },
        { value: 2, color: "e2e607", escalationType: EscalationTypeEnum.OptionalEscalation },
        { value: 3, color: "e61717", escalationType: EscalationTypeEnum.RequiredEscalation }
    ];
    copySnackbar = false;
    uploadFieldName = 'questionFiles';
    uploadQuestion: QuestionModel | null = null;
    maxUploadFileSize = 50;
    snackbarFileTooBig = false;
    uploadQuestionGroup: QuestionGroupModel | null = null;
    currentQuestionFile: any = {
        questionGroupIndex: 0,
        questionIndex: 0,
        fileIndex: 0,
        canPrev: false,
        canNext: false,
        file: null
    };
    Vda63ProcessElements = VDA63.ProcessElements;
    missingQuestions: string[] = [];
    missingQuestionsDialog = false;

    @Ref() form!: any;
    @Ref() inputFile!: any;
    @Ref() questionFileDetail!: QuestionFileDetail;
    @Ref() aiGeneratorDialog!: AiQuestionGenerator;
    @Ref() aiImproveDialog!: AiQuestionImprove;

    get isReadonly (): boolean {
        return !this.hasPermissions([PermissionTypeEnum.EditAllAudits]);
    }

    get hasQuestions(): boolean {
      return this.questionGroups?.selectMany(x => x.questions ?? [])?.where(x => x.name != null && x.name.length > 0).any() ?? false;
    }

    @Watch("audit", { deep: true })
    @Watch("questionGroups", { deep: true })
    @Watch("questionGroupsToDelete", { deep: true })
    questionGroupsChanged () {
        const changed = !isEqual(this.questionGroupsOriginal, this.questionGroups) || !isEqual(this.auditOriginal, this.audit);
        this.hasChanges = changed || this.questionGroupsToDelete.length > 0;
    }
    
    get isVda63(): boolean {
        return this.audit?.category?.responseType == ResponseTypeEnum.VDA63;
    }

    mounted () {
        const tenantId = authStore.useGetter(AuthGetter.GetTenant);
        const tenant = (tenantsStore.useGetter(CrudGetter.Data) as TenantModel[]).first(x => x.id == tenantId);
        this.maxUploadFileSize = tenant.maxUploadFileSize ? tenant.maxUploadFileSize : 50;

        this.audit = {...auditsStore.useGetter(CrudGetter.Detail)} as AuditModel;
        if (this.audit!.explanationType == AuditExplanationTypeEnum.ByAudit) {
            this.fillAuditExplanations(this.audit);
        }
        this.auditOriginal = cloneDeep(this.audit);
        this.loading = false;
        this.load();
    }

    load (): Promise<any> {
        return new Promise<any>((resolve, reject) => {
           questionGroupsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<QuestionGroupModel>({
                field: "position", index: 1, order: "asc"
            }, [
                { field: "auditId", op: "eq", comparand: this.auditId }
            ]));
            this.subscribe(questionGroupsStore, CrudReponse.GetAll).then((e: QuestionGroupModel[]) => {
                e.forEach(g => {
                    g.questions?.sort((a, b) => a.position! - b.position!);
                    if (this.audit!.explanationType == AuditExplanationTypeEnum.ByQuestionGroup) {
                        this.fillQuestionGroupExplanations(g);
                    }
                });
                this.questionGroups = e;
                this.questionGroupsOriginal = cloneDeep(this.questionGroups);
                this.hasChanges = false;
                resolve(e);
            }).catch((e) => {
                reject(e);
            }); 
        });
    }

    save () {
        if (this.form.validate()) {
            if (this.audit!.category!.responseType == ResponseTypeEnum.VDA63) {
                this.missingQuestions = this.validateVda63();
                if (this.missingQuestions.any()) {
                    this.missingQuestionsDialog = true;
                    return;
                }
            }
            this.saving = true;
            const audit = {...this.audit};
            const questionGroups = [...this.questionGroups];
            audit.aiGenerated = questionGroups.any(x => x.aiGenerated == true) || questionGroups.selectMany(x => x.questions ?? []).any(x => x.aiGenerated == true);
            auditsStore.dispatch(CrudAction.Update, { item: audit });
            this.subscribe(auditsStore, CrudReponse.Update).then(() => {
                this.deleteAllPromise(this.questionGroupsToDelete).then(() => {

                    questionGroups.forEach(x => {
                        x.position = questionGroups.indexOf(x) + 1;
                        x.questions!.forEach(e => {
                            e.position = x.questions!.indexOf(e) + 1
                            e.questionFiles!.forEach(qf => {
                              qf.position = e.questionFiles!.indexOf(qf) + 1
                            });
                        });
                    })
                    this.saveAllPromise(questionGroups).then(() => {
                        this.load().then(() => {
                            this.questionGroupsToDelete = [];
                            this.saving = false;
                        }).catch((e) => {
                            this.saving = false;
                            throw e;
                        });
                    }).catch((e) => {
                        this.saving = false;
                        throw e;
                    });
                }).catch((e) => {
                    this.saving = false;
                    throw e;
                });
            });
        }
    }

    deleteAuditClick () {
        this.deleteDialog = true;
    }

    deleteAudit () {
        this.deleting = true;
        auditsStore.dispatch(CrudAction.Delete, { id: this.auditId });
        this.subscribe(auditsStore, CrudReponse.Delete).then((e: any) => {
            this.deleting = false;
            this.deleteDialog = false;
            this.$router.push({ name: "Audits", params: { tenantId: this.tenantId } });
        }).catch((e: any) => {
            this.deleting = false;
        });
    }

    addQuestionGroup () {
        this.questionGroups.push({
            id: Guid.EmptyGuid(),
            name: "",
            questions: [],
            auditId: this.auditId,
            questionGroupExplanations: []
        } as QuestionGroupModel)
    }

    addQuestion (questionGroup: QuestionGroupModel) {
        questionGroup.questions!.push({
            id: Guid.EmptyGuid(),
            code: "",
            name: "",
            description: "",
            disagreementResponse: "",
            questionFiles: []
        } as QuestionModel)
    }

    deleteQuestionGroupClick (questionGroup: QuestionGroupModel) {
        if (questionGroup.id != Guid.EmptyGuid()) {
            this.questionGroupsToDelete.push(questionGroup);
        }
        this.questionGroups.splice(this.questionGroups.indexOf(questionGroup), 1);
    }

    public deleteAllPromise (payload: Array<any>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.deleteAllPromisePop(payload, resolve, reject);
        });
    }

    public saveAllPromise (payload: Array<any>): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.saveAllPromisePop(payload, resolve, reject);
        });
    }

    private deleteAllPromisePop(payload: Array<any>, resolve: (value: any) => void, reject: (reason?: any) => void) {
        if (payload.length > 0) {
            const payloadItem = payload[0];
            if (payloadItem.id != Guid.EmptyGuid())  {
                questionGroupsStore.dispatch(CrudAction.Delete, { id: payloadItem.id });
                this.subscribe(questionGroupsStore, CrudReponse.Delete).then((e: any) => {
                    payload.shift();
                    this.deleteAllPromisePop(payload, resolve, reject);
                }).catch((e: any) => {
                    reject(e);
                });
            }
        }
        else {
            resolve(null);
        }
    }

    private saveAllPromisePop(payload: Array<any>, resolve: (value: any) => void, reject: (reason?: any) => void) {
        if (payload.length > 0) {
            const payloadItem = payload[0];
            if (payloadItem.id == Guid.EmptyGuid())  {
                questionGroupsStore.dispatch(CrudAction.Create, { item: payloadItem });
                this.subscribe(questionGroupsStore, CrudReponse.Create).then((e: any) => {
                    payload.shift();
                    this.saveAllPromisePop(payload, resolve, reject);
                }).catch((e: any) => {
                    reject(e);
                });
            }
            else {
                questionGroupsStore.dispatch(CrudAction.Update, { item: payloadItem });
                this.subscribe(questionGroupsStore, CrudReponse.Update).then((e: any) => {
                    payload.shift();
                    this.saveAllPromisePop(payload, resolve, reject);
                }).catch((e: any) => {
                    reject(e);
                });
            }
        }
        else {
            resolve(null);
        }
    }

    groupMoveUp(questionGroup: QuestionGroupModel) {
        const originalIndex = this.questionGroups!.indexOf(questionGroup);
        const newIndex = originalIndex - 1;
        this.questionGroups!.splice(originalIndex, 1);
        this.questionGroups!.splice(newIndex, 0, questionGroup);
    }

    groupMoveDown(questionGroup: QuestionGroupModel) {
        const originalIndex = this.questionGroups!.indexOf(questionGroup);
        const newIndex = originalIndex + 1;
        this.questionGroups!.splice(originalIndex, 1);
        this.questionGroups!.splice(newIndex, 0, questionGroup);
    }

    questionMoveUp(questionGroup: QuestionGroupModel, question: QuestionModel) {
        const originalIndex = questionGroup.questions!.indexOf(question);
        const newIndex = originalIndex - 1;
        questionGroup.questions!.splice(originalIndex, 1);
        questionGroup.questions!.splice(newIndex, 0, question);
    }

    questionMoveDown(questionGroup: QuestionGroupModel, question: QuestionModel) {
        const originalIndex = questionGroup.questions!.indexOf(question);
        const newIndex = originalIndex + 1;
        questionGroup.questions!.splice(originalIndex, 1);
        questionGroup.questions!.splice(newIndex, 0, question);
    }

    showResponseValues (index: number) {
        if (index != this.showResponsiveValue)
            this.showResponsiveValue = index;
        else
            this.showResponsiveValue = null;
    }

    copyToClipboard (value: string) {
        if (value) {
            navigator.clipboard.writeText(value);
            this.copySnackbar = true;
        }
    }

    fillQuestionGroupExplanations (item: QuestionGroupModel) {
        this.audit!.category!.responseValues!.forEach((x => {
            if (!item.questionGroupExplanations!.any(n => n.responseValue == x.value)) {
                item.questionGroupExplanations!.push({
                    id: Guid.EmptyGuid(),
                    explanationDescription: null,
                    responseValue: x.value
                });
            }
        }));
        item.questionGroupExplanations = item.questionGroupExplanations!.orderBy(x => x.responseValue!).toArray();
    }

    fillAuditExplanations (audit: AuditModel) {
        this.audit!.category!.responseValues!.forEach((x => {
            if (!audit.auditExplanations!.any(n => n.responseValue == x.value)) {
                audit.auditExplanations!.push({
                    id: Guid.EmptyGuid(),
                    explanationDescription: null,
                    responseValue: x.value
                });
            }
        }));
        audit.auditExplanations = audit.auditExplanations!.orderBy(x => x.responseValue!).toArray();
    }

    activateFileUpload (question: QuestionModel, questionGroup: QuestionGroupModel) {
        this.uploadQuestionGroup = questionGroup;
        this.uploadQuestion = question;
        this.inputFile.click();
    }

    uploadFile () {
        const fileSize = this.inputFile.files[0].size as number;
        if (fileSize <= this.maxUploadFileSize * 1000000) {
            var formData = new FormData();
            formData.append("file", this.inputFile.files[0]);
            const tenantId = authStore.useGetter(AuthGetter.GetTenant);
            const token = getAccessToken();
            axios.post((window as any).ApiService.baseUrl + "/api/files/upload", formData, {
                headers: {
                    "TenantId": tenantId,
                    "Authorization": `Bearer ${token}`
                }
            }).then((r) => {
                const fileModel = r.data.returnValue as FileModel;
                this.fileUploaded(fileModel);
            });
        }
        else {
            this.snackbarFileTooBig = true;
        }
        this.inputFile.value = "";
    }

    fileUploaded (fileModel: FileModel) {
        const questionGroupIndex = this.questionGroups.indexOf(this.uploadQuestionGroup!);
        const questionIndex = this.questionGroups[questionGroupIndex].questions!.indexOf(this.uploadQuestion!);
        this.questionGroups![questionGroupIndex!].questions![questionIndex!].questionFiles!.push({
            questionId: this.uploadQuestion!.id,
            fileId: fileModel.id,
            file: fileModel,
            createdAt: moment().format("YYYY-MM-DDThh:mm:ss"),
            tenantId: this.tenantId
        });
        this.hasChanges = true;
    }

    deleteQuestionFile (questionGroupIndex: number, questionIndex: number, fileIndex: number) {
        this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.splice(fileIndex, 1);
        this.deleteDialog = false;
    }

    deleteQuestionFileFromDialog (item: FileModel) {
        if (item) {
            const questionGroupIndex = this.currentQuestionFile.questionGroupIndex;
            const questionIndex = this.currentQuestionFile.questionIndex;
            const fileIndex = this.currentQuestionFile.fileIndex;
            this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.splice(fileIndex, 1);
        }
    }

    showQuestionFileDetail (questionGroupIndex: number, questionIndex: number, fileIndex: number) {
        this.currentQuestionFile = {
            questionGroupIndex: questionGroupIndex,
            questionIndex: questionIndex,
            fileIndex: fileIndex,
            canPrev: fileIndex > 0 ? true : false,
            canNext: fileIndex + 1 < this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.length ? true : false,
            file: this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles![fileIndex]
        };
        console.log(this.questionGroups[questionGroupIndex].questions![questionIndex].questionFiles!.length);
        if (this.currentQuestionFile)
            this.questionFileDetail.open(this.currentQuestionFile);
    }

    prevQuestionFile () {
        let targetFile: QuestionFileModel | null = null;
        targetFile = this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles![this.currentQuestionFile.fileIndex - 1];
        if (targetFile) {
            this.currentQuestionFile.file = targetFile;
            this.currentQuestionFile.fileIndex--;
            this.currentQuestionFile.canPrev = this.currentQuestionFile.fileIndex > 0 ? true : false;
            this.currentQuestionFile.canNext = true;
        }
    }

    nextQuestionFile () {
        let targetFile: QuestionFileModel | null = null;
        targetFile = this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles![this.currentQuestionFile.fileIndex + 1];
        if (targetFile) {
            this.currentQuestionFile.file = targetFile;
            this.currentQuestionFile.fileIndex++;
            this.currentQuestionFile.canPrev = this.currentQuestionFile.fileIndex > 0 ? true : false;
            this.currentQuestionFile.canNext = this.currentQuestionFile.fileIndex + 1 < this.questionGroups[this.currentQuestionFile.questionGroupIndex].questions![this.currentQuestionFile.questionIndex].questionFiles!.length ? true : false;
        }
    }
    
    vda63HasSubteps(processElement: string | null) {
        return VDA63.ProcessElementsSteps.firstOrDefault(x => x.Element == processElement)?.UseSubElements == true;
    }
    
    vda63GetElementSubSteps(processElement: string | null): string[] {
        if (this.vda63HasSubteps(processElement)) {
            return VDA63.ProcessElementsSteps.first(x => x.Element == processElement).SubElements.toArray().select(x => x.SubElement).toArray();
        }
        return [];
    }
    
    vda63GetQuestionOptions(processElement: string | null, processSubElement: string | null): string[] {
        if (this.vda63HasSubteps(processElement)) {
            return VDA63.ProcessElementsSteps.firstOrDefault(x => x.Element == processElement)?.SubElements.firstOrDefault(x => x.SubElement == processSubElement)?.Questions.toArray().select(x => x.QuestionCode).toArray() ?? [];
        }
        else {
            return VDA63.ProcessElementsSteps.firstOrDefault(x => x.Element == processElement)?.Questions.toArray().select(x => x.QuestionCode).toArray() ?? [];
        }
    }
    
    validateVda63(): string[] {
        const missingQuestions: string[] = []
        const processElements = this.questionGroups.select(x => x.processElement).distinct().toArray();
        for (const processElement of processElements) {
            const VdaProcessElement = VDA63.ProcessElementsSteps.first(x => x.Element == processElement);
            const questions = this.questionGroups.where(x => x.processElement == processElement).selectMany(x => x.questions ?? []).toArray();
            let requiredQuestions: Vda63QuestionModel[] = [];
            if (VdaProcessElement.UseSubElements) {
                requiredQuestions = VdaProcessElement.SubElements.selectMany(x => x.Questions).toArray();
            }
            else {
                requiredQuestions = VdaProcessElement.Questions.toArray();
            }
            requiredQuestions.where(x => !questions.any(n => n.code == x.QuestionCode)).toArray().forEach(x => {
                missingQuestions.push(x.QuestionCode);
            });
        }
        return missingQuestions;
    }

    openGenerator() {
        this.aiGeneratorDialog.open(!(this.questionGroups?.any() ?? false));
    }

  openImprove() {
    this.aiImproveDialog.open();
  }

  onAiResult(action: GenerationActionEnum, questions: AiSuggestedQuestion[]) {
      if (action == GenerationActionEnum.Replace) {
        this.questionGroups = [];
      }
      let groupIndex = this.questionGroups.count() == 0 ? 0 : this.questionGroups.select(x => x.position!).max();
      questions.groupBy(x => x.category!).toArray().forEach(category => {
        let questionIndex = 0;
        this.questionGroups.push({
          id: Guid.EmptyGuid(),
          auditId: this.auditId,
          name: category.first().category,
          position: ++groupIndex,
          aiGenerated: true,
          questions: category.select(n => {
            return {
              id: Guid.EmptyGuid(),
              code: n.code,
              name: n.question,
              position: ++questionIndex,
              questionFiles: [],
              aiGenerated: true,
              description: n.description,
              disagreementResponse: n.disagreementResponse
            } as QuestionModel;
          }).toArray(),
          questionGroupExplanations: []
        } as QuestionGroupModel);
      });
      this.aiGeneratorDialog.close();
  }

  onAiImproveResult(generateDescription: boolean, generateDisagreementResponse: boolean, questions: AiSuggestedQuestion[]) {
    let groupIndex = this.questionGroups.count() == 0 ? 0 : this.questionGroups.select(x => x.position!).max();
    questions.groupBy(x => x.category!).toArray().forEach(category => {
      let questionIndex = 0;
      if (this.questionGroups.any(x => x.name == category.first().category)) {
        const existingCategory = this.questionGroups.first(x => x.name == category.first().category);
        category.toArray().forEach(q => {
          if (existingCategory!.questions!.any(n => n.id == q.id)) {
            const existingQuestion = existingCategory!.questions!.first(n => n.id == q.id);
            existingQuestion.name = q.question;
            if (generateDescription) {
              existingQuestion.description = q.description;
            }
            if (generateDisagreementResponse) {
              existingQuestion.disagreementResponse = q.disagreementResponse;
            }
          }
          else {
            existingCategory!.questions!.push({
              id: Guid.EmptyGuid(),
              code: q.code,
              name: q.question,
              position: ++questionIndex,
              questionFiles: [],
              aiGenerated: true,
              description: q.description,
              disagreementResponse: q.disagreementResponse
            } as QuestionGroupModel);
          }
        });
      }
      else {
        this.questionGroups.push({
          id: Guid.EmptyGuid(),
          auditId: this.auditId,
          name: category.first().category,
          position: ++groupIndex,
          aiGenerated: true,
          questions: category.select(n => {
            return {
              id: Guid.EmptyGuid(),
              code: n.code,
              name: n.question,
              position: ++questionIndex,
              questionFiles: [],
              aiGenerated: true,
              description: n.description,
              disagreementResponse: n.disagreementResponse
            } as QuestionModel;
          }).toArray(),
          questionGroupExplanations: []
        } as QuestionGroupModel);
      }
    });
    this.aiImproveDialog.close();
  }

}
